let top;
if (document.body.clientWidth > 1664) {
  top = 135;
} else {
  top = 67;
}

function scrollAnimation(currentY, targetY) {
    targetY = targetY-top
    scrollAnimationFn(currentY, targetY)
}

function scrollAnimationFn(currentY, targetY){
    let needScrollTop = targetY - currentY
    let _currentY = currentY
    setTimeout(() => {
      // const dist = needScrollTop / 80
      _currentY += 10;
      window.scrollTo(_currentY, currentY)
      if (needScrollTop > 10 || needScrollTop < -10) {
        scrollAnimationFn(_currentY, targetY)
      } else {
        window.scrollTo(_currentY, targetY)
      }
    }, 1)
}
module.exports = {
    scrollAnimation
}
