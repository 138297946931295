<template>
  <div>
    <div class="workbench_lunbo flex_center">
      <div :class="index==0?'white workbench_lunbo_prev cur_point':'blue workbench_lunbo_prev cur_point'" @click="direction('prev')" :style="{cursor:index==0?'not-allowed':'pointer'}">
        <img :src="index==0?prevImg:nextImg" :class="index==0?'':'deg180'" />
      </div>
      <div class="workbench_lunbo_con">
        <ul class="workbench_link_div flex_center_box" ref="lunbo">
          <li v-for="(item,index) in titData" :key="index" class="cur_point">
            <div @click="go(index+1)">
              <div class="workbench_tit">{{item.tit}}</div>
              <div class="workbench_icon"><img :src="item.img" /></div>
              <div class="workbench_tol">{{item.tol}}</div>
              <div class="workbench_num">{{item.num}}</div>
            </div>
            <div class="workbench_bottom" @click="onResearch(index+1)">
              <div class="iconfont icon-xiayiye-xianxing3-0"></div>
            </div>
          </li>
        </ul>
      </div>
      <div :class="index==(lunboIndex-5)?'white workbench_lunbo_next cur_point':'blue workbench_lunbo_next cur_point'" @click="direction('next')" :style="{cursor:index==3?'not-allowed':'pointer'}">
        <img :src="index==(lunboIndex-5)?prevImg:nextImg" :class="index==(lunboIndex-5)?'deg180':''">
      </div>
    </div>
    <div class="table_common_work" ref="performance1">
      <div class="table_common_work_tit flex_center_between_box">
        <div>我的发票</div>
        <div class="flex_center_end_box workbench_table_title_more" @click="go(1)">查看更多<div class="iconfont icon-xiayiye-xianxing3-0"></div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart flex_center_between_box">
          <div ref="echarts1" class="table_common_work_echart1" v-show="this.echarts1Flag"></div>
          <div class="nodata" v-show="!this.echarts1Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details" v-show="this.echarts1Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">发票总数：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.totalInvoiceAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.totalInvoiceAmount | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">已付款总额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.totalPaid) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.totalPaid | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">未付款总额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.totalUnpaid) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.totalUnpaid | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit over1" title="已贷款发票总额：">已贷款发票总额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.totalLoanInvoices) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.totalLoanInvoices | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">贷款额比率：</div>
                <div>{{data.loanRatio}}%</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="table_common_work_echart flex_center_between_box">
          <div ref="echarts2" class="table_common_work_echart1" v-show="this.echarts2Flag"></div>
          <div class="nodata" v-show="!this.echarts2Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details" v-show="this.echarts2Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">发票总数量：</div>
                <div>{{data.totalInvoiceQuantity}}张</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">已融资数量：</div>
                <div>{{data.quantityFinanced}}张</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">未融资数量：</div>
                <div>{{data.unfundedQuantity}}张</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">融资数比率：</div>
                <div>{{data.financingRatio}}%</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div class="workbench_table_title_total flex_box">
          <div class="workbench_table_title_total_bottom_dashed">
            <div class="flex_box">
              <div class="workbench_table_title_total_tit">平均账期(天)</div>
              <div class="workbench_table_title_total_button">{{data.avgAccount}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">最长账期(天)</div>
              <div class="workbench_table_title_total_button">{{data.maxAccount}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">最短账期(天)</div>
              <div class="workbench_table_title_total_button">{{data.minAccount}}</div>
              <div class="vertical_line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table_common_work" ref="performance2">
      <div class="table_common_work_tit flex_center_between_box">
        <div>我的融资</div>
        <div class="flex_center_end_box workbench_table_title_more" @click="go(2)">查看更多<div class="iconfont icon-xiayiye-xianxing3-0"></div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart table_common_work_echart2 flex_center_between_box">
          <div ref="echarts3" class="table_common_work_echart1" v-show="echarts3Flag"></div>
          <div class="nodata" v-show="!this.echarts3Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details table_common_work_echart_details2" v-show="echarts3Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">贷款总额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.totalLoan) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.totalLoan | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">最大贷款额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.maxLoanAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.maxLoanAmount | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">最小贷款额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.minLoanAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.minLoanAmount | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">平均贷款额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.avgLoanAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.avgLoanAmount | NumFormat}}元</div>
                </el-popover>
              </li>
            </ul>
          </div>
        </div>
        <div class="table_common_work_echart table_common_work_echart2 flex_center_between_box">
          <div ref="echarts4" class="table_common_work_echart1" v-show="this.echarts4Flag"></div>
          <div class="nodata" v-show="!this.echarts4Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details table_common_work_echart_details2" v-show="this.echarts4Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">投资方数量：</div>
                <div>{{data.investorNum}}</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">发起融资数：</div>
                <div>{{data.initiatedFinancingNum}}</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">融资成功数：</div>
                <div>{{data.successfulFinancingNum}}</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">融资失败数：</div>
                <div>{{data.failFinancingNum}}</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">融资申请中数：</div>
                <div>{{data.financingInProgress}}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div class="workbench_table_title_total flex_box">
          <div class="workbench_table_title_total_bottom_dashed">
            <div class="flex_box">
              <div class="workbench_table_title_total_tit">投资最高利率(%)</div>
              <div class="workbench_table_title_total_button">{{data.maxInterestRate}}%</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">投资平均利率(%)</div>
              <div class="workbench_table_title_total_button">{{data.avgInterestRate}}%</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">投资最低利率(%)</div>
              <div class="workbench_table_title_total_button">{{data.minInterestRate}}%</div>
              <div class="vertical_line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table_common_work" ref="performance3">
      <div class="table_common_work_tit flex_center_between_box">
        <div>我的还款</div>
        <div class="flex_center_end_box workbench_table_title_more" @click="go(3)">查看更多<div class="iconfont icon-xiayiye-xianxing3-0"></div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart flex_center_between_box" style="margin-bottom:0">
          <div ref="echarts5" class="table_common_work_echart1" v-show="this.echarts5Flag"></div>
          <div class="nodata" v-show="!this.echarts5Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details" v-show="this.echarts5Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">已还款金额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.repaymentAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.repaymentAmount | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">未还款金额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.outstandAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.outstandAmount | NumFormat}}元</div>
                </el-popover>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">逾期金额：</div>
                <el-popover placement="top" trigger="hover" :content="filter(data.overdueAmount) + '元'" popper-class="popper_class_account">
                  <div slot="reference" class="text_overflow">{{data.overdueAmount | NumFormat}}元</div>
                </el-popover>
              </li>
            </ul>
          </div>
        </div>
        <div class="table_common_work_echart flex_center_between_box" style="margin-bottom:0">
          <div ref="echarts6" class="table_common_work_echart1" v-show="this.echarts6Flag"></div>
          <div class="nodata" v-show="!this.echarts6Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details" v-show="this.echarts6Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">已还款笔数：</div>
                <div>{{data.repaymentNum}}笔</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">未还款笔数：</div>
                <div>{{data.outstandNum}}笔</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">逾期笔数：</div>
                <div>{{data.overdueNum}}笔</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="table_common_work" ref="performance4">
      <div class="table_common_work_tit flex_center_between_box">
        <div>我的伙伴</div>
        <div class="flex_center_end_box workbench_table_title_more" @click="go(4)">查看更多<div class="iconfont icon-xiayiye-xianxing3-0"></div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart table_common_work_echart7 echart_between">
          <!-- <div class="c">伙伴评级</div> -->
          <div ref="echarts7" class="table_common_work_echart1_allwidth" v-show="echarts7Flag"></div>
          <div class="nodata nodataTop" v-show="!echarts7Flag">暂无数据，敬请期待…</div>
        </div>
        <div class="table_common_work_echart table_common_work_echart7 echart_between">
          <div ref="echarts8" class="table_common_work_echart1_allwidth" v-show="echarts8Flag"></div>
          <div class="nodata nodataTop" v-show="!echarts8Flag">暂无数据，敬请期待…</div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart table_common_work_echart7">
          <!-- <div class="c">伙伴评级</div> -->
          <div ref="echarts9" class="table_common_work_echart1_allwidth" v-show="echarts9Flag"></div>
          <div class="nodata nodataTop" v-show="!echarts9Flag">暂无数据，敬请期待…</div>
        </div>
        <div class="table_common_work_echart table_common_work_echart7">
          <div ref="echarts10" class="table_common_work_echart1_allwidth" v-show="echarts10Flag"></div>
          <div class="nodata nodataTop" v-show="!echarts10Flag">暂无数据，敬请期待…</div>
        </div>
      </div>
      <div>
        <div class="workbench_table_title_total workbench_table_title_total_top flex_box">
          <div class="workbench_table_title_total_bottom_dashed">
            <div class="flex_box">
              <div class="workbench_table_title_total_tit">伙伴总量(个)</div>
              <div class="workbench_table_title_total_button">{{data.totalPartners}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">平台伙伴数(个)</div>
              <div class="workbench_table_title_total_button">{{data.platformPartnersNum}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">最大交易金额(元)</div>
              <div class="workbench_table_title_total_button">{{data.maxAmount | NumFormat}}</div>
              <div class="vertical_line"></div>
            </div>
          </div>
        </div>
        <div class="workbench_table_title_total workbench_table_title_total_bottom flex_box">
          <div class="workbench_table_title_total_bottom_dashed">
            <div class="flex_box">
              <div class="workbench_table_title_total_tit">最小交易金额(元)</div>
              <div class="workbench_table_title_total_button">{{data.minAmount | NumFormat}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1">平均交易金额(元)</div>
              <div class="workbench_table_title_total_button">{{data.avgAmount | NumFormat}}</div>
              <div class="vertical_line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table_common_work" ref="performance6">
      <div class="table_common_work_tit flex_center_between_box">
        <div>我的服务</div>
        <div class="flex_center_end_box workbench_table_title_more" @click="go(7)">查看更多<div class="iconfont icon-xiayiye-xianxing3-0"></div>
        </div>
      </div>
      <div class="flex_center_between_box table_common_work_echart_div">
        <div class="table_common_work_echart table_common_work_echart11 flex_center_between_box">
          <div ref="echarts11" class="table_common_work_echart1" v-show="this.echarts11Flag"></div>
          <div class="nodata" v-show="!echarts11Flag">暂无数据，敬请期待…</div>
          <div class="table_common_work_echart_details table_common_work_echart_details1" v-show="this.echarts11Flag">
            <ul>
              <li class="flex_center_between_box">
                <div class="tit">服务总数：</div>
                <div>{{data.processedService + data.untreatedService}}个</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">已处理服务数：</div>
                <div>{{data.processedService}}个</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">未处理服务数：</div>
                <div>{{data.untreatedService}}个</div>
              </li>
              <li class="flex_center_between_box">
                <div class="tit">即将过期服务数：</div>
                <div>{{data.willExpireService}}个</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <template>
      <el-backtop :visibility-height='220' :bottom='200' :right='150' class="backtop_div">
        <div class="iconfont icon-huidaodingbu"></div>
      </el-backtop>
    </template>
  </div>
</template>
<script>
import { supplier, financingNumAmount, partnerDealAmount, partnerDealRegion, partnerAmountRatio, partnerCredit } from "@/api/supplier.js";
import { mixins1 } from "@/mixins/index";
import { scrollAnimation } from '@/assets/js/top'
export default {
  mixins: [mixins1],
  data() {
    return {
      index: 0,
      menuList: [
        {
          name: '我的工作台',
          path: '/system/supplier/workbench'
        },
        {
          name: '我的发票',
          path: '/system/supplier/applyFinancing'
        },
        {
          name: '我的融资',
          path: '/system/supplier/myFinancing'
        },
        {
          name: '我的还款',
          path: '/system/supplier/myAccount'
        },
        {
          name: '我的伙伴',
          path: '/system/supplier/myPartner'
        },
        {
          name: '我的信用',
          path: '/system/supplier/myCredit'
        },
        {
          name: '我的获客',
          path: '/system/supplier/myCustomers'
        },
        {
          name: '我的服务',
          path: '/system/supplier/myService'
        },
        {
          name: '功能定义',
          path: '/system/supplier/functionDefinition'
        },
      ],
      titData: [
        {
          tit: '我的发票',
          img: require('@/assets/img/system/supplier/whome1.png'),
          tol: '发票总数(张)',
          num: '-',
        },
        {
          tit: '我的融资',
          img: require('@/assets/img/system/supplier/whome2.png'),
          tol: '融资总数(次)',
          num: '-',
        },
        {
          tit: '我的还款',
          img: require('@/assets/img/system/supplier/whome3.png'),
          tol: '待还款金额(元)',
          num: '-',
        },
        {
          tit: '我的伙伴',
          img: require('@/assets/img/system/supplier/whome4.png'),
          tol: '伙伴总数(个)',
          num: '-',
        },
        {
          tit: '我的信用',
          img: require('@/assets/img/system/supplier/whome5.png'),
          tol: '我的信用评级',
          num: '-',
        },
        {
          tit: '我的获客',
          img: require('@/assets/img/system/supplier/whome7.png'),
          tol: '我的获客数(个)',
          num: '-',
        },
        {
          tit: '我的服务',
          img: require('@/assets/img/system/supplier/whome6.png'),
          tol: '服务总数(个)',
          num: '-',
        },
        {
          tit: '功能定义',
          img: require('@/assets/img/system/supplier/whome8.png'),
          tol: '自定义(%)',
          num: '-',
        }
      ],
      img1: require('@/assets/img/system/supplier/whome1.png'),
      img2: require('@/assets/img/system/supplier/whome2.png'),
      img3: require('@/assets/img/system/supplier/whome3.png'),
      img4: require('@/assets/img/system/supplier/whome4.png'),
      img5: require('@/assets/img/system/supplier/whome5.png'),
      img6: require('@/assets/img/system/supplier/whome6.png'),
      img7: require('@/assets/img/system/supplier/whome7.png'),
      img8: require('@/assets/img/system/supplier/whome8.png'),
      prevImg: require('@/assets/img/prev.png'),
      nextImg: require('@/assets/img/next.png'),
      fourChart: null,
      list9grlegend: 14,
      list9grlegendWidth: 15,
      data: {},
      List1: [{
        value: 30,
        name: '未付款'
      }, {
        value: 70,
        name: '已付款'
      }],
      List2: [{
        value: 30,
        name: '未融资'
      }, {
        value: 70,
        name: '已融资'
      }],
      // List3: [{
      //     value: 10000,
      //     name: 'FN2021111201'
      // }, {
      //     value: 9000,
      //     name: 'FN2021111202'
      // }, {
      //     value: 8000,
      //     name: 'FN2021111203'
      // }, {
      //     value: 7000,
      //     name: 'FN2021111204'
      // }, {
      //     value: 6000,
      //     name: 'FN2021111205'
      // }, {
      //     value: 5000,
      //     name: 'FN2021111206'
      // }, {
      //     value: 4000,
      //     name: 'FN2021111207'
      // }, {
      //     value: 3000,
      //     name: 'FN2021111208'
      // }, {
      //     value: 2000,
      //     name: 'FN2021111209'
      // }, {
      //     value: 1000,
      //     name: 'FN20211112010'
      // }],
      List3: [],

      List4: [{
        value: 30,
        name: '融资失败数'
      }, {
        value: 70,
        name: '融资成功数'
      }],
      List5: [{
        value: 30,
        name: '未还款金额'
      }, {
        value: 70,
        name: '已还款金额'
      }],
      List6: [{
        value: 30,
        name: '未还款笔数'
      }, {
        value: 70,
        name: '已还款笔数'
      }],
      List7: [],
      List7Total: '',
      List8Length: '',
      List9Length: '',
      List10Length: '',
      List8: [],
      List9: [],
      List10: [],

      List11: [{
        value: 30,
        name: '未处理'
      }, {
        value: 70,
        name: '已处理'
      }],
      listName3A: ['3A1', '3A2', '3A3', '3A4', '3A5', '3A6', '3A7', '3A8', '3A9', '3A10', '3A11', '3A12', '3A13', '3A14', '3A15', '3A16', '3A17', '3A18', '3A19',],
      echarts1Flag: true,
      echarts2Flag: true,
      echarts3Flag: true,
      echarts4Flag: true,
      echarts5Flag: true,
      echarts6Flag: true,
      echarts7Flag: true,
      echarts8Flag: true,
      echarts9Flag: true,
      echarts10Flag: true,
      echarts11Flag: true,
    }
  },
  created() {
    this.tableWidth()
    this.resizeFn()
    this.getDate()
    // var name = "The Window";
    // var object = {
    //   name: "My Object",
    //   getNameFunc: function () {
    //     return function () {
    //       return this.name;
    //     };
    //   }
    // };
    // alert(object.getNameFunc()());
  },
  methods: {
    async getDate() {
      let data = await supplier()
      this.titData[0].num = data.data.totalInvoices
      this.titData[1].num = data.data.totalFinancing
      this.titData[2].num = this.filter(data.data.repaidAmount)
      this.titData[3].num = data.data.partnerNum
      this.titData[4].num = data.data.aaaRating
      this.titData[5].num = data.data.getCust
      this.titData[6].num = data.data.totalService
      this.titData[7].num = data.data.functionPercent
      this.data = data.data
      this.List1[0].value = this.data.totalUnpaid
      this.List1[1].value = this.data.totalPaid
      this.List2[0].value = this.data.unfundedQuantity
      this.List2[1].value = this.data.quantityFinanced
      this.List4[0].value = this.data.failFinancingNum
      this.List4[1].value = this.data.successfulFinancingNum
      this.List5[0].value = this.data.outstandAmount
      this.List5[1].value = this.data.repaymentAmount
      this.List6[0].value = this.data.outstandNum
      this.List6[1].value = this.data.repaymentNum
      this.List11[0].value = this.data.untreatedService
      this.List11[1].value = this.data.processedService
      data = await financingNumAmount()
      data.data.forEach((item, index) => {
        this.List3[index] = []
        this.List3[index][0] = item.num
        this.List3[index][1] = item.amount
      });
      data = await partnerDealAmount()
      console.log(data.data.length)
      this.List8Length = data.data.length
      data.data.forEach((item, index) => {
        this.List8[index] = []
        this.List8[index][0] = item.name
        this.List8[index][1] = item.amount
      });
      console.log(this.List8)
      data = await partnerAmountRatio()
      console.log(data)
      this.List9Length = data.data.length
      data.data.forEach((item, index) => {
        this.List9[index] = {}
        this.List9[index].value = item.ratio
        this.List9[index].name = item.name
      });
      data = await partnerDealRegion()
      console.log(data)
      this.List10Length = data.data.length
      let i = 0
      data.data.forEach((item, index) => {
        if (item.num) {
          this.List10[i] = []
          this.List10[i][0] = item.name
          this.List10[i][1] = item.num
          i++
        }
      });
      console.log(this.List10)
      data = await partnerCredit()
      this.List7Total = data.data[0].total
      console.log(data.data[0])
      for (let i = 0; i < 19; i++) {
        this.List7[i] = []
        this.List7[i][0] = this.listName3A[i]
        if (data.data[0].total) {
          this.List7[i][1] = ((data.data[0][this.listName3A[i]] / data.data[0].total) * 100).toFixed(2)
        } else {
          this.List7[i][1] = data.data[0][this.listName3A[i]]
        }
      }
      console.log(this.List7)
      this.$nextTick(() => {
        this.echarts1(this.pieFontSize, this.pieFontSize1, this.legendWidth, this.lengendJg)
        this.echarts2(this.pieFontSize, this.pieFontSize1)
        this.echarts3(this.pieFontSize, this.marginHeight, this.pieFontSize2)
        this.echarts4(this.pieFontSize, this.pieFontSize1)
        this.echarts5(this.pieFontSize, this.pieFontSize1)
        this.echarts6(this.pieFontSize, this.pieFontSize1)
        this.echarts7(this.pieFontSize, this.pieFontSize1, this.marginHeight)
        this.echarts8(this.pieFontSize, this.marginHeight, this.pieFontSize1, this.yLineHeight)
        this.echarts9(this.pieFontSize, this.pieFontSize1, this.list9grlegend, this.list9grlegendWidth)
        this.echarts10(this.pieFontSize, this.pieFontSize1, this.yLineHeight)
        this.echarts11(this.pieFontSize, this.pieFontSize1)
      })
    },
    onResearch(index) {
      console.log(index)
      this.$nextTick(function () {
        if (index === 1) {
          const top = this.$refs.performance1.offsetTop
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          scrollAnimation(scrollTop, top)
        } else if (index === 2) {
          const top = this.$refs.performance2.offsetTop
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          scrollAnimation(scrollTop, top)
        } else if (index === 3) {
          const top = this.$refs.performance3.offsetTop
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          scrollAnimation(scrollTop, top)
        } else if (index === 4) {
          const top = this.$refs.performance4.offsetTop
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          scrollAnimation(scrollTop, top)
        } else if (index === 6) {
          const top = this.$refs.performance6.offsetTop
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          scrollAnimation(scrollTop, top)
        } else if (index === 5) {
          this.go(5)
        }
      })
    },
    go(index) {
      this.$router.push(this.menuList[index].path)
    },
    echarts1(pieFontSize, pieFontSize1, legendWidth, lengendJg) {
      console.log(this.List1)
      if (this.List1[0].value == 0 && this.List1[1].value == 0) {
        this.echarts1Flag = false
      }
      if (this.fourChart !== null) {
        this.fourChart.dispose()
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts1)
      let that = this
      const option = {
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            params['value'] = that.filter(params['value'])
            if (params.value > 1) {
              return params['name'] + '：' + params['value'] + '元'
            } else {
              return params['name'] + '：' + params['value'] + '元'
            }
          }
        },
        legend: {
          orient: 'horizontal',
          left: '0%',
          itemGap: lengendJg,
          itemWidth: legendWidth,
          itemHeight: legendWidth,
        },
        series: [
          {
            z: '2',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              fontSize: pieFontSize1,
              position: 'inside',
              borderWidth: 0,
              color: '#fff',
              formatter: function (params) { return (params.percent.toFixed(2) + '%') }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.List1
          },
          {
            z: '1',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              borderWidth: 0,
              color: 'inherit',
              formatter: function (params) {
                return (params['name'])
              }

            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              show: false,
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.List1
          }
        ]
      }
      this.fourChart.setOption(option)
    },
    echarts2(pieFontSize, pieFontSize1) {
      if (this.List2[0].value == 0 && this.List2[1].value == 0) {
        this.echarts2Flag = false
      }
      // if (this.fourChart !== null) {
      //   this.fourChart.dispose()
      // }
      this.fourChart = this.$echarts.init(this.$refs.echarts2)
      const option = {
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (params.value > 1) {
              return params['name'] + '：' + params['value'] + '张'
            } else {
              return params['name'] + '：' + params['value'] + '张'
            }
          }
        },
        legend: {
          orient: 'horizontal',
          left: '0%',
          itemGap: 20,
          itemWidth: 15,
          itemHeight: 15
        },
        series: [
          {
            z: '2',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              fontSize: pieFontSize1,
              position: 'inside',
              borderWidth: 0,
              color: '#fff',
              formatter: function (params) { return (params.percent.toFixed(0) + '%') }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.List2
          },
          {
            z: '1',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              borderWidth: 0,
              color: 'inherit',
              formatter: function (params) { return (params['name']) }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              show: false,
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.List2
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    echarts3(pieFontSize, marginHeight) {
      this.fourChart = this.$echarts.init(this.$refs.echarts3)
      console.log(this.List3)
      if (this.List3.length == 0) {
        this.echarts3Flag = false
      }
      let that = this
      const option = {
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: 60,
            margin: marginHeight,
            textStyle: {
              color: '#92A2BC',
              fontSize: pieFontSize
            },
            formatter: function (value) {
              console.log(value.length)
              if (value.length < 10) {
                return value
              } else {
                return value.slice(0, 9) + '...'
              }
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: true,
            length: 3
          },
          name: '单位：元',
          nameTextStyle: {
            color: '#162747',
            fontSize: pieFontSize
          },
          axisLabel: {
            fontSize: pieFontSize,
            fontFamily: 'Microsoft YaHei',
            formatter: function (value) {
              if (value.toString().length < 6) {
                return value.toString()
              } else {
                return value.toString().slice(0, 5) + '...'
              }
            }
          },
        },
        grid: {
          left: '14%',
          top: '18%',
          bottom: '32%'
        },
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            params.data[1] = that.filter(params.data[1])
            if (params.value > 1) {
              return params.data[0] + ':' + params.data[1] + '元'
            } else {
              return params.data[0] + ':' + params.data[1] + '元'
            }
          }
        },
        series: [
          {
            type: 'bar',
            center: ['50%', '50%'],
            radius: [0, '70%'],
            barWidth: '12',
            itemStyle: {
              normal: {
                color: '#339C9B',
                barBorderRadius: [100, 100, 0, 0]
              },
            },
            data: this.List3
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    echarts4(pieFontSize, pieFontSize1) {
      console.log(this.List4)
      if (this.List4[0].value == 0 && this.List4[1].value == 0) {
        this.echarts4Flag = false
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts4)
      const option = {
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (params.value > 1) {
              return params['name'] + '：' + params['value'] + '个'
            } else {
              return params['name'] + '：' + params['value'] + '个'
            }
          }
        },
        legend: {
          orient: 'horizontal',
          left: '0%',
          itemGap: 20,
          itemWidth: 15,
          itemHeight: 15
        },
        series: [
          {
            z: '2',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '60%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              fontSize: pieFontSize1,
              position: 'inside',
              borderWidth: 0,
              color: '#fff',
              formatter: function (params) { return (params.percent.toFixed(0) + '%') }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.List4
          },
          {
            z: '1',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '60%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              borderWidth: 0,
              color: 'inherit',
              formatter: function (params) { return (params['name']) }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              show: false,
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.List4
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    echarts5(pieFontSize, pieFontSize1) {
      // if (this.fourChart !== null) {
      //   this.fourChart.dispose()
      // }
      if (this.List5[0].value == 0 && this.List5[1].value == 0) {
        this.echarts5Flag = false
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts5)
      let that = this
      const option = {
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            params['value'] = that.filter(params['value'])
            if (params.value > 1) {
              return params['name'] + '：' + params['value'] + '元'
            } else {
              return params['name'] + '：' + params['value'] + '元'
            }
          }
        },
        legend: {
          orient: 'horizontal',
          left: '0%',
          itemGap: 20,
          itemWidth: 15,
          itemHeight: 15
        },
        series: [
          {
            z: '2',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              fontSize: pieFontSize1,
              position: 'inside',
              borderWidth: 0,
              color: '#fff',
              formatter: function (params) { return (params.percent.toFixed(0) + '%') }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.List5
          },
          {
            z: '1',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              borderWidth: 0,
              color: 'inherit',
              formatter: function (params) { return (params['name']) }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              show: false,
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.List5
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    echarts6(pieFontSize, pieFontSize1) {
      if (this.List6[0].value == 0 && this.List6[1].value == 0) {
        this.echarts6Flag = false
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts6)
      const option = {
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (params.value > 1) {
              return params['name'] + '：' + params['value'] + '笔'
            } else {
              return params['name'] + '：' + params['value'] + '笔'
            }
          }
        },
        legend: {
          orient: 'horizontal',
          left: '0%',
          itemGap: 20,
          itemWidth: 15,
          itemHeight: 15
        },
        series: [
          {
            z: '2',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              fontSize: pieFontSize1,
              position: 'inside',
              borderWidth: 0,
              color: '#fff',
              formatter: function (params) { return (params.percent.toFixed(0) + '%') }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.List6
          },
          {
            z: '1',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              borderWidth: 0,
              color: 'inherit',
              formatter: function (params) { return (params['name']) }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.List6
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    echarts7(pieFontSize, pieFontSize1, marginHeight) {
      if (this.List7Total == 0) {
        this.echarts7Flag = false
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts7)
      const option = {
        title: {
          text: '伙伴评级',
          textStyle: {
            color: '#162747',
            fontFamily: "微软雅黑",
            fontWeight: 'normal',
            fontSize: pieFontSize1
          }
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: 60,
            margin: marginHeight,
            textStyle: {
              color: '#92A2BC',
              fontSize: pieFontSize
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            interval: 'auto',
            fontSize: pieFontSize,
            fontFamily: 'Microsoft YaHei',
            formatter: '{value} %'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: true,
            length: 3
          },
        },
        grid: {
          left: '9%',
          right: '2%',
          top: '20%',
          bottom: '25%',
        },
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (params.value > 1) {
              return params['value'] + '%'
            } else {
              return params['value'] + '%'
            }
          }
        },
        series: [
          {
            type: 'bar',
            center: ['50%', '50%'],
            radius: [0, '70%'],
            barWidth: '12',
            itemStyle: {
              normal: {
                color: '#339C9B',
                barBorderRadius: [100, 100, 0, 0]
              },
            },
            data: this.List7
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    echarts8(pieFontSize, marginHeight, pieFontSize1, yLineHeight) {
      console.log(this.List8Length)
      if (this.List8Length == 0) {
        this.echarts8Flag = false
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts8)
      let that = this
      const option = {
        title: {
          text: '交易金额',
          textStyle: {
            color: '#162747',
            fontFamily: "微软雅黑",
            fontWeight: 'normal',
            fontSize: pieFontSize1
          }
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            rotate: 60,
            margin: marginHeight,
            textStyle: {
              color: '#92A2BC',
              fontSize: pieFontSize
            },
            formatter: function (value) {
              console.log(value.length)
              if (value.length < 6) {
                return value
              } else {
                return value.slice(0, 5) + '...'
              }
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: true,
            length: 3
          },
          name: '元',
          nameTextStyle: {
            lineHeight: yLineHeight
          },
          axisLabel: {
            fontSize: pieFontSize,
            fontFamily: 'Microsoft YaHei',
            formatter: function (value) {
              if (value.toString().length < 6) {
                return value.toString()
              } else {
                return value.toString().slice(0, 5) + '...'
              }
            }
          },
        },
        grid: {
          left: '9%',
          right: '2%',
          top: '20%',
          bottom: '25%',
        },
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            params['value'][1] = that.filter(params['value'][1])
            if (params.value > 1) {
              return params['value'] + '元'
            } else {
              return params['value'] + '元'
            }
          }
        },
        series: [
          {
            type: 'bar',
            center: ['50%', '50%'],
            radius: [0, '70%'],
            barWidth: '12',
            itemStyle: {
              normal: {
                color: '#339C9B',
                barBorderRadius: [100, 100, 0, 0]
              },
            },
            data: this.List8
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    echarts9(pieFontSize, pieFontSize1, list9grlegend, list9grlegendWidth) {
      // if (this.fourChart !== null) {
      //   this.fourChart.dispose()
      // }
      if (this.List9Length == 0) {
        this.echarts9Flag = false
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts9)
      const option = {
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (params.value > 1) {
              return params['name'] + '：' + params['percent'] + '%'
            } else {
              return params['name'] + '：' + params['percent'] + '%'
            }
          }
        },
        title: {
          text: '交易金额占比',
          textStyle: {
            color: '#162747',
            fontFamily: "微软雅黑",
            fontWeight: 'normal',
            fontSize: pieFontSize1
          }
        },
        grid: {
          left: '2%',
        },
        legend: {
          orient: 'vertical',
          right: '0%',
          align: 'right',
          itemGap: list9grlegend,
          itemWidth: list9grlegendWidth,
          itemHeight: list9grlegendWidth,
          formatter: function (name) {
            return name.length>6 ? name.substr(0,6)+'…':name;
          },
          tooltip: {
              show: true
          }
        },
        series: [
          {
            z: '1',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['38%', '50%'],
            radius: [0, '60%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              borderWidth: 0,
              color: 'inherit',
              width:84,
              
              overflow:'truncate',
              formatter: function (params) {
                console.log('111',params)
                return (params['name'] + '：' + parseFloat(params.percent).toFixed(1) + '%')
              }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              show: false,
              color: function (params) {
                const colorList = [
                  '#0AC8C6',
                  '#339C9B',
                  '#267675',
                  '#004F4E',
                  '#316BCD',
                  '#4A8DFF',
                  '#76B3FF',
                  '#E86C19',
                  '#FF9B15',
                  '#FAC858',
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.List9
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    echarts10(pieFontSize, pieFontSize1, yLineHeight) {
      if (this.List10Length == 0) {
        this.echarts10Flag = false
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts10)
      const option = {
        title: {
          text: '交易地区统计',
          textStyle: {
            color: '#162747',
            fontFamily: "微软雅黑",
            fontWeight: 'normal',
            fontSize: pieFontSize1
          }
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            // rotate: 60,
            margin: 8,
            textStyle: {
              color: '#92A2BC',
              fontSize: 12
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#92A2BC'
            }
          },
          axisTick: {
            show: true,
            length: 3
          },
          name: '单数',
          nameTextStyle: {
            lineHeight: yLineHeight
          },
          axisLabel: {
            fontSize: pieFontSize,
            fontFamily: 'Microsoft YaHei'
          },
        },
        grid: {
          left: '7%',
          right: '2%',
          top: '20%',
          bottom: '19%',
        },
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (params.value > 1) {
              return params.data[0] + ',' + params.data[1] + '个'
            } else {
              return params.data[0] + ',' + params.data[1] + '个'
            }
          }
        },
        series: [
          {
            type: 'bar',
            center: ['50%', '50%'],
            radius: [0, '70%'],
            barWidth: '12',
            itemStyle: {
              normal: {
                color: '#339C9B',
                barBorderRadius: [100, 100, 0, 0]
              },
            },
            data: this.List10
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    echarts11(pieFontSize, pieFontSize1) {
      // if (this.fourChart !== null) {
      //   this.fourChart.dispose()
      // }
      if (this.List11[0].value == 0 && this.List11[1].value == 0) {
        this.echarts11Flag = false
      }
      this.fourChart = this.$echarts.init(this.$refs.echarts11)
      const option = {
        tooltip: {
          borderWidth: 0,
          textStyle: {
            color: '#162747',
          },
          formatter: function (params) {
            if (params.value > 1) {
              return params['name'] + '服务数' + params['value'] + '个'
            } else {
              return params['name'] + '服务数' + params['value'] + '个'
            }
          }
        },
        legend: {
          orient: 'horizontal',
          left: '0%',
          itemGap: 20,
          itemWidth: 15,
          itemHeight: 15
        },
        series: [
          {
            z: '2',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 5,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              fontSize: pieFontSize1,
              position: 'inside',
              borderWidth: 0,
              color: '#fff',
              formatter: function (params) { return (params.percent.toFixed(0) + '%') }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.List11
          },
          {
            z: '1',
            type: 'pie',
            clockwise: false,
            startAngle: 72,
            center: ['50%', '55%'],
            radius: [0, '70%'],
            label: {
              show: true,
              fontSize: pieFontSize,
              borderWidth: 0,
              color: 'inherit',
              formatter: function (params) { return (params['name']) }
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 30
            },
            itemStyle: {
              show: false,
              color: function (params) {
                const colorList = [
                  '#FF9B15',
                  '#339C9B'
                ]
                return colorList[params.dataIndex]
              },
            },
            data: this.List11
          }
        ]
      }
      this.fourChart.setOption(option)
      // this.fourChart.resize()
    },
    resizeFn() {
      if (document.body.clientWidth > 1664) {
        this.marginHeight = 8
        this.yLineHeight = 18
        this.list9grlegend = 14
        this.list9grlegendWidth = 15
      } else {
        this.marginHeight = 3
        this.yLineHeight = -11
        this.list9grlegend = 7
        this.list9grlegendWidth = 12
      }
    },
  }
}
</script>
<style scoped>
  .nodata {
    width: 220px;
    height: 20px;
    margin: 0 auto;
    line-height: 20px;
    font-size: 16px;
    font-family: '微软雅黑';
    color: #92a2bc;
  }
  .nodataTop {
    margin-top: 150px;
  }
  .text_overflow {
    width: 85px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;
    cursor: pointer;
  }
  .deg180 {
    transform: rotate(180deg);
  }
  /* .workbench_lunbo {
                                              width: 100%;
                                              padding-top: 10px;
                                              padding-bottom: 40px;
                                          }
                                          .workbench_lunbo_prev {
                                              width: 50px;
                                              height: 50px;
                                              margin-right: 10px;
                                              border-radius: 50%;
                                          }
                                          .workbench_lunbo_next {
                                              width: 50px;
                                              height: 50px;
                                              margin-left: 10px;
                                              border-radius: 50%;
                                          } */
  .blue {
    box-shadow: 0px 8px 14px 0px rgba(47, 160, 159, 0.23);
  }
  .white {
    box-shadow: 0px 8px 14px 0px rgba(96, 135, 135, 0.23);
  }
  .backtop_div {
    width: 40px;
    height: 40px;
    background: #ffffff;
    box-shadow: 0px 6px 12px 0px rgba(50, 150, 149, 0.19);
  }
  ::v-deep .el-icon-caret-top:before {
    display: none;
    content: '\e6e0';
  }
  .backtop_div .icon-huidaodingbu {
    font-size: 36px;
    color: #339c9b;
  }
  .workbench_table_title_more .icon-xiayiye-xianxing3-0 {
    padding-left: 6px;
    color: #339c9b;
  }
  .workbench_table_title_total_tit {
    width: 225px;
  }
  .workbench_table_title_total_tit1 {
    width: 340px;
  }
  .table_common_work_echart1_allwidth_tit {
    height: 16px;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .table_common_work_echart_details2 {
    height: 280px !important;
  }
  .table_common_work_echart_details {
    width: 240px;
    height: 236px;
    padding: 10px 20px 0 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px rgba(9, 44, 55, 0.28) inset;
  }
  .table_common_work_echart_details1 {
    width: 320px;
  }
  .table_common_work_echart_details li {
    line-height: 38px;
    font-size: 14px;
  }
  .table_common_work_echart_details .tit {
    color: #92a2bc;
  }
  .table_common_work_echart1 {
    width: 400px;
    height: 100%;
  }
  .table_common_work_echart1_allwidth {
    width: 100%;
    height: 100%;
  }
  .table_common_work_echart11 {
    margin-bottom: 0 !important;
    width: 100% !important;
  }
  .table_common_work_echart7 {
    height: 340px !important;
  }
  .table_common_work_echart2 {
    height: 320px !important;
  }
  .table_common_work_echart {
    width: 700px;
    height: 276px;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #edf2f5;
  }
  .table_common_work_tit {
    line-height: 22px;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .workbench_lunbo_con {
    width: 1380px;
    overflow: hidden;
  }
  .workbench_link_div {
    width: auto;
    transform: translate3d(0px, 0px, 0px);
  }

  .workbench_tit {
    line-height: 60px;
    font-size: 16px;
    color: #339c9b;
  }

  .workbench_icon {
    width: 130px;
    height: 140px;
    padding-bottom: 10px;
    margin: 0 auto;
    border-radius: 50%;
  }

  .workbench_tol {
    line-height: 38px;
    font-size: 14px;
    color: #92a2bc;
  }

  .workbench_num {
    height: 30px;
    line-height: 30px;
    margin-bottom: 30px;
    font-family: 'Microsoft YaHei Bold';
    font-size: 30px;
  }

  .workbench_bottom {
    width: 100%;
    height: 30px;
    background-color: #339c9b;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }

  .workbench_bottom .icon-xiayiye-xianxing3-0 {
    color: #fff;
    transform: rotate(90deg);
    padding-left: 12px !important;
    font-size: 18px;
  }
  @media screen and (max-width: 1664px) {
    .nodata {
      width: 150px;
      height: 12px;
      margin: 0 auto;
      line-height: 12px;
      font-size: 12px;
      font-family: '微软雅黑';
      color: #92a2bc;
    }
    .nodataTop {
      margin-top: 100px;
    }
    .text_overflow {
      width: 74px;
    }
    .backtop_div {
      width: 27px;
      height: 27px;
      box-shadow: 0px 4px 8px 0px rgba(50, 150, 149, 0.19);
    }
    .backtop_div .icon-huidaodingbu {
      font-size: 24px;
    }
    .workbench_table_title_more .icon-xiayiye-xianxing3-0 {
      padding-left: 4px;
    }
    .workbench_table_title_total_tit {
      width: 150px;
    }
    .workbench_table_title_total_tit1 {
      width: 227px;
    }
    .table_common_work_echart1_allwidth_tit {
      height: 11px;
      font-size: 12px;
      margin-bottom: 14px;
    }
    .table_common_work_echart_details2 {
      height: 187px !important;
    }
    .table_common_work_echart_details {
      width: 180px;
      height: 157px;
      padding: 7px 14px 0 20px;
      border-radius: 7px;
      background-color: #fff;
      box-shadow: 0px 0px 4px 0px rgba(9, 44, 55, 0.28) inset;
    }
    .table_common_work_echart_details1 {
      width: 213px;
    }
    .table_common_work_echart_details li {
      /* line-height: 25px; */
      line-height: 27px;
      font-size: 12px;
      /* transform: scale(0.85);
                                                  white-space: nowrap;
                                                  margin-left: -9px; */
    }
    .table_common_work_echart1 {
      width: 250px;
    }
    .table_common_work_echart7 {
      height: 227px !important;
    }
    .table_common_work_echart2 {
      height: 213px !important;
    }
    .table_common_work_echart {
      width: 467px;
      height: 184px;
      padding: 14px;
      margin-bottom: 7px;
      border-radius: 7px;
      background-color: #edf2f5;
    }
    .table_common_work_tit {
      line-height: 15px;
      font-size: 12px;
      margin-bottom: 14px;
    }
    .workbench_lunbo_con {
      width: 920px;
      overflow: hidden;
    }
    .workbench_link_div {
      width: auto;
    }

    .workbench_tit {
      line-height: 40px;
      font-size: 12px;
      color: #339c9b;
    }

    .workbench_icon {
      width: 87px;
      height: 93px;
      padding-bottom: 7px;
      margin: 0 auto;
      border-radius: 50%;
    }

    .workbench_tol {
      line-height: 25px;
      font-size: 12px;
      color: #92a2bc;
    }

    .workbench_num {
      height: 20px;
      line-height: 20px;
      margin-bottom: 20px;
      font-family: 'Microsoft YaHei Bold';
      font-size: 20px;
    }

    .workbench_bottom {
      width: 100%;
      height: 20px;
      background-color: #339c9b;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
    }

    .workbench_bottom .icon-xiayiye-xianxing3-0 {
      color: #fff;
      transform: rotate(90deg);
      padding-left: 8px !important;
      font-size: 12px;
    }
  }
</style>